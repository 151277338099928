<template>
  <div class="bodyDiv">
 <link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Saira+Condensed:wght@300;400;500;600;700&display=swap" rel="stylesheet">
<meta name="viewport" content="width=device-width,initial-scale=1.0">
    <!-- <pre>{{[candidate]}}</pre> -->
    <div class="cv-area">
      <div class="vertical-line"></div>
      <div class="title-content">
        <h2>
          {{ candidate.fullName.split(" ")[0] }}
          <span>{{
            candidate.fullName.split(" ")[
              candidate.fullName.split(" ").length - 1
            ]
          }}</span>
        </h2>
      </div>
      <div class="contact-section">
        <div class="yellow-rectangule">
          <div class="divider">
            <p>{{ candidate.profession }}</p>
          </div>
        </div>
        <div class="transparent-rectangule">
          <div class="divider">
            <p>
              {{ candidate.telephone }} <br />{{ candidate.email }} <br />{{
                candidate.address
              }}
            </p>
          </div>
        </div>
      </div>
      <h3>{{$t('form_candidate_profile')}}</h3>
      <div class="profile">
        <p>
          {{ candidate.presentation }}
        </p>
      </div>
      <div class="cv-content">
        <div class="content-left">
          <h2>{{$t('test_form_cat_2')}}</h2>
          <div class="bg-cinza">
            <p><strong>{{$t('Extra_curricular')}}</strong></p>
            <ul>
              <li
                class="col"
                v-for="(skill, index) in candidate.otherSkills"
                :key="index"
              >
                {{ skill }}
              </li>
            </ul>
          </div>
          <br />
          <h2>{{$t('languages')}}</h2>
          <div class="bg-cinza">
            <div
              class="row"
              v-for="(language, index) in candidate.languages"
              :key="index"
            >
              <div class="col">
                <p>{{ $t(language.name) }}</p>
              </div>
              <div class="col">
                <div class="progress-out">
                  <div
                    class="progress"
                    :style="'width:' + languageLevel(language.level)"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <h2>{{$t('Personal_Data')}}</h2>
          <div class="bg-cinza">
            <div class="row">
              <div class="col">
                <p>{{$t('form_candidate_gender')}}</p>
              </div>
              <div class="col">
                <div>{{ candidate.genre }}</div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <p>{{$t('form_candidate_nacion')}}</p>
              </div>
              <div class="col">
                <div>{{ candidate.nationality }}</div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <p>{{$t('form_candidate_birth')}}</p>
              </div>
              <div class="col">
                <div>{{ formatDate(candidate.dateBirth) }}</div>
              </div>
            </div>
          </div>
          <br />
          <h2>{{$t('form_candidate_networks')}}</h2>
          <div class="bg-cinza">
            <div class="row">
              <div class="col">
                <p>Linkedin</p>
              </div>
              <div class="col"  v-if="candidate.media.linkedin">
                <div>{{ candidate.media.linkedin.length > 35? candidate.media.linkedin.substring(0,35)+'-':candidate.media.linkedin }}</div>
                <div>{{ candidate.media.linkedin.length > 70? candidate.media.linkedin.substring(35,70)+'...':'' }}</div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <p>Facebook</p>
              </div>
              <div class="col" v-if="candidate.media.facebook">
                <div>{{ candidate.media.facebook.length > 35? candidate.media.facebook.substring(0,35)+'-': candidate.media.facebook }}</div>
                <div>{{ candidate.media.facebook.length > 70? candidate.media.facebook.substring(35,70)+'...': ''}}</div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <p>Twitter</p>
              </div>
              <div class="col" v-if="candidate.media.twitter">
                <div>{{ candidate.media.twitter.length > 35? candidate.media.twitter.substring(0,35)+'-':candidate.media.twitter }}</div>
                <div>{{ candidate.media.twitter.length > 70? candidate.media.twitter.substring(35,70)+'...':'' }}</div>
              </div>
            </div>
          </div>
          <br />
          <h2>{{$t('form_candidate_ref')}}</h2>
          <div class="bg-cinza">
            <div
              v-for="reference in candidate.references"
              :key="reference.fullName"
            >
              <p>{{ reference.fullName }}</p>
              <p>{{ reference.office }}</p>
              <p>{{ reference.telephone }}</p>
            </div>
          </div>
        </div>

        <div class="content-right">
          <h2>{{$t('form_candidate_experience_label')}}</h2>

          <div
            v-for="(expe, index) in candidate.professionalExperience"
            :key="index"
          >
            <p>
              <strong>{{ expe.entity }}</strong>
            </p>
            <small
              >{{ formatDate(expe.startDate) }} -
              {{ formatDate(expe.endDate) }}</small
            >
            <p>
              <strong>{{ expe.office }}</strong>
              <!-- <span style="text-transform:uppercase; margin-left:1%">
                {{ candidate.availability }}</span
              > -->
            </p>
            <p>
              {{ expe.description.substring(0, 350) }}
            </p>
          </div>
          <br />

          <h2>{{$t('form_candidate_academic_level')}}</h2>
          <div
            v-for="(formation, index) in candidate.formation"
            :key="`${index}`"
          >
            <p>
              <strong>{{ formation.institution }}</strong>
            </p>
            <small
              >{{ formatDate(formation.startDate) }} -
              {{ formatDate(formation.endDate) }}</small
            >
            <p>
              <span>{{ $t(formation.level) }}</span> em
              {{ $t(formation.course) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { API_URL } from "@/api";
import moment from "moment";
export default {
  name: "CV",
  data: () => ({
    apiUrl: API_URL,
  }),
  props: {
    candidate: {
      type: Object,
    },
  },

  created() {
    this.candidateId = this.$route.params.id;
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  methods: {
     languageLevel(level) {
      if (level === "Basic" || level === "Básico" || level === "basic") {
        return "30%";
      } else if (level === "Intermediate" || level === "Intermédio" || level === "intermediate" ) {
        return "50%";
      } else {
        return "100%";
      }
    },
    formatDate(date) {
      if (date) {
        moment.locale(this.$i18n.locale);
        return moment(date).format("LL");
      }
    },
  },
};
</script>
<style scoped>
.bodyDiv {
  font-family: "Saira Condensed", sans-serif;
  background-color: rgb(255, 255, 255);
  padding: 0%;
  margin: 0;
}
h2 {
  font-weight: 500;
  margin: 10px 0;
}
h2 span {
  font-weight: 300;
}
.cv-content {
  margin-top: 5%;
}
.cv-content h2 {
  text-transform: uppercase;
}
p {
  margin: 5px 0 !important;
}
small {
  margin: 2px 0 !important;
}
.vertical-line {
  height: 70px;
  width: 3px;
  background-color: black;
  margin-left: 15px;
}
.title-content {
  font-family: "Saira Condensed", sans-serif;
  max-width: 100px;
  font-size: 2.6rem;
  font-weight: 100 !important;
  line-height: 1;
  text-transform: uppercase;
}
.yellow-rectangule {
  left: 0;
  width: 200px;
  justify-content: center;
  padding-left: 2%;
  margin-right: 5%;
   background-color: #e5b64c;
  z-index: auto;
}
.divider {
  border-left: 3px solid black;
  padding-left: 5%;
}
.profile {
  border-left: 3px solid black;
  padding-left: 2%;
  width: 600px;
  margin-left: 15px;
}
.contact-section {
  font-family: "Saira Condensed", sans-serif;
  display: flex;
  align-items: center;
}
.cv-content {
  font-family: "Saira Condensed", sans-serif;
  display: flex;
}
.transparent-rectangule {
  width: 300px;
}
.content-left {
  width: 260px;
}
.progress {
  border-radius: 10px;
  background-color: #141838;
  width: 50px;
  height: 5px;
}
.progress-out {
  border-radius: 10px;
  background-color: #f7a400;
  width: 100px;
  height: 5px;
}
.content-right {
  border-left: 3px solid black;
  padding-left: 2%;
  width: 500px;
}
.content-right p span {
  background-color: lightgray;
  padding: 0.5%;
  margin-right: 2%;
}
.bg-cinza {
  background-color: lightgray;
  padding: 5%;
  margin-right: 10%;
}
.row {
  display: flex;
  align-items: center;
}
.col {
  padding: 0 2%;
  
}

.cv-area {
  padding: 0 5% 5%;
  background-color: rgb(255, 255, 255);
  width: 800px;
  height: auto;
  align-content: center;
  margin: 0 auto;
}
ul {
  /* list-style-type: circle; */
  margin-left: 20%;
  padding: 0;
}
</style>
