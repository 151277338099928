var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-heigt pt-8",attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex flex-row justify-space-between align-center px-3"},[_c('div',[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.routerBack()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)],1),(_vm.$route.query.from === 'ranking')?_c('div',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t("assign_rating"))+":")]),_c('div',{staticClass:"text-center mr-2"},[_c('v-rating',{attrs:{"color":"yellow darken-3","background-color":"grey darken-1","empty-icon":"$ratingFull","half-increments":"","hover":"","small":""},on:{"input":function($event){return _vm.setRating()}},model:{value:(_vm.rating),callback:function ($$v) {_vm.rating=$$v},expression:"rating"}})],1)]):_c('div',[[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"mx-2",attrs:{"link":"","label":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.dialogNote = true}}},on),[_vm._v(_vm._s(_vm.$t("Notepad")))])]}}])},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('tooltip_notpad'))}})])],[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-6",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogList = true}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.$t("AddtoFavorites"))+" ")],1)]}}])},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('tooltip_favlist_candidate'))}})])]],2)]),_c('v-row',{staticClass:"mt-7"},[(_vm.getCandidate)?_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('profile-details',{attrs:{"candidate":_vm.getCandidate,"filter":_vm.filter,"vacancyId":_vm.$route.query.vacancyId}})],1):_vm._e(),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"3"}},[(
          _vm.$route.query.from != 'ranking' &&
          _vm.valueTLanguage() &&
          _vm.vacancy.weight &&
          _vm.valueTFormation() &&
          _vm.valueTProfessionalExperience()
        )?_c('div',[_c('div',{staticClass:"mb-2 text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t("Matching_with_the_vacancy"))+" ")]),_c('v-divider',{staticClass:"py-3"}),_c('v-sheet',{attrs:{"color":"white","elevation":"2","width":"100%"}},[_c('div',{staticClass:"justify-center align-center ml-3 pb-1"},[(_vm.valueTLanguage())?_c('div',{staticClass:"mb-3 pt-3"},[_vm._v(" "+_vm._s(_vm.$t("languages"))+" "),_c('v-progress-linear',{staticStyle:{"max-width":"300px","min-width":"225px"},attrs:{"color":_vm.getLanguageVacancyPerc(_vm.candidate, _vm.vacancy) <=
                  _vm.valueTLanguage() / 3
                    ? 'red'
                    : _vm.getLanguageVacancyPerc(_vm.candidate, _vm.vacancy) <=
                      _vm.valueTLanguage() / 2
                    ? 'orange'
                    : 'success',"height":"10","value":_vm.getLanguageVacancyPerc(_vm.candidate, _vm.vacancy),"buffer-value":_vm.valueTLanguage()}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.DFormatter(_vm.getLanguageVacancyPerc(_vm.candidate, _vm.vacancy)))+"%")])])],1):_vm._e(),(_vm.vacancy.weight)?_c('div',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t("Related_areas"))+" "),_c('v-progress-linear',{staticStyle:{"max-width":"300px","min-width":"225px"},attrs:{"color":_vm.getAreaCandVacancyPer(_vm.candidate, _vm.vacancy) <=
                  _vm.vacancy.weight / 3
                    ? 'red'
                    : _vm.getAreaCandVacancyPer(_vm.candidate, _vm.vacancy) <=
                      _vm.vacancy.weight / 2
                    ? 'orange'
                    : 'success',"height":"10","value":_vm.getAreaCandVacancyPer(_vm.candidate, _vm.vacancy),"buffer-value":_vm.vacancy.weight}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.DFormatter(_vm.getAreaCandVacancyPer(_vm.candidate, _vm.vacancy)))+"%")])])],1):_vm._e(),(_vm.valueTFormation())?_c('div',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t("form_candidate_academic_level"))+" "),_c('v-progress-linear',{staticStyle:{"max-width":"300px","min-width":"225px"},attrs:{"color":_vm.getFormationVacancyPerc(_vm.candidate, _vm.vacancy) <=
                  _vm.valueTFormation() / 3
                    ? 'red'
                    : _vm.getFormationVacancyPerc(_vm.candidate, _vm.vacancy) <=
                      _vm.valueTFormation() / 2
                    ? 'orange'
                    : 'success',"height":"10","value":_vm.getFormationVacancyPerc(_vm.candidate, _vm.vacancy),"buffer-value":_vm.valueTFormation()}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.DFormatter(_vm.getFormationVacancyPerc(_vm.candidate, _vm.vacancy)))+"%")])])],1):_vm._e(),(_vm.valueTProfessionalExperience())?_c('div',{staticClass:"mb-6"},[_vm._v(" "+_vm._s(_vm.$t("form_candidate_experience_label"))+" "),_c('v-progress-linear',{staticStyle:{"max-width":"300px","min-width":"225px"},attrs:{"color":_vm.getExpCandVacancyPer(_vm.candidate, _vm.vacancy) <=
                  _vm.valueTProfessionalExperience() / 3
                    ? 'red'
                    : _vm.getExpCandVacancyPer(_vm.candidate, _vm.vacancy) <=
                      _vm.valueTProfessionalExperience() / 2
                    ? 'orange'
                    : 'success',"height":"10","value":_vm.getExpCandVacancyPer(_vm.candidate, _vm.vacancy),"buffer-value":_vm.valueTProfessionalExperience()}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.DFormatter(_vm.getExpCandVacancyPer(_vm.candidate, _vm.vacancy)))+"%")])])],1):_vm._e()])])],1):_vm._e(),_c('div',{staticClass:"mb-2 text-uppercase mt-3"},[_vm._v(_vm._s(_vm.$t("Profilesnotseen")))]),_c('v-divider',{staticClass:"py-3"}),_c('v-row',[_c('v-col',{staticClass:"list"},_vm._l((_vm.candidates),function(can){return _c('div',{key:can.id},[_c('v-card',{staticClass:"mb-2 pa-6 py-5",on:{"click":function($event){return _vm.go(can.id)}}},[_c('v-list-item',[_c('v-list-item-avatar',{staticClass:"mx-auto",attrs:{"color":"grey","size":"38"}},[_c('img',{attrs:{"src":can.photo
                      ? (_vm.apiUrl + "/images/candidate/" + (can.photo))
                      : "/avatar.png","alt":"John"}})]),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-2 font-weight-medium"},[_c('strong',[_vm._v(_vm._s(can.name.split(" ")[0]))]),_vm._v(" "+_vm._s(can.lastName.split(" ")[0]))]),_c('div',{staticClass:"caption text--disabled subtitle"},[_c('span',{staticStyle:{"color":"#0098CE"}},[_vm._v(_vm._s(can.profession))])])],1)],1)],1)],1)],1)}),0)],1)],1)],1),_c('note-candidate-dialog',{attrs:{"candidateId":_vm.getCandidate,"dialog":_vm.dialogNote,"ranking":false,"vacancyId":_vm.vacancy},on:{"close":function($event){_vm.dialogNote = false}}}),_c('add-candidate-list-dialog',{attrs:{"candidateId":_vm.candidateId,"dialog1":_vm.dialogList,"vacancyId":_vm.vacancyId},on:{"close":function($event){_vm.dialogList = false}}}),_c('error-dialog',{attrs:{"dialog":_vm.showError,"message":_vm.error},on:{"close":function($event){_vm.showError = false}}}),_c('progress-dialog',{attrs:{"processing":_vm.isLoading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }