import gql from 'graphql-tag'

export default gql`{
  id
  available
  candidacies {
    specialDate
  }
  description
  entity {
    id
    about
    logo
    name
    media {
      facebook
      linkedin
      twitter
      website
    }
  }
  finalDate
  formation {
    course
    level
    status
    institution
    year
    weight
  }
  languages {
    name
    level
    weight
  }
  locale
  category
  place
  private
  weight
      areaInterest
  professionalExperience {
    description
    yearsExperience
    weight
  }
  publicationDate
  status
  title
  type
}`