export default class Interview {
  constructor (id, address, candidates, date, message, guests, recruters, subject, telephone, time) {
    this.id = id
    this.address = address
    this.candidates = candidates
    this.date = date
    this.message = message
    this.guests = guests
    this.recruters = recruters
    this.subject = subject
    this.telephone = telephone
    this.time = time
  }
}