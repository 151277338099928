import gql from 'graphql-tag'
import vacancyFragment from './VacancyFragment'
import VacancyFragmentCandiadate from './VacancyFragmentCandiadate'

export const GET_NEW_VACANCIES_QUERY = gql`
  query GetNewVacancies {
    vacancies: getNewVacancies {
      ...${vacancyFragment}
    }
  }
`

export const GET_PRIVATE_VACANCIES_QUERY = gql`
  query GetPrivateVacancies($filterVacancy: FilterVacancy) {
    Vacancies: getPrivateVacancies(filterVacancy: $filterVacancy) {
      ...${vacancyFragment}
    }
  }
`

export const GET_VACANCIES_CANDIDATE_QUERY = gql`
  query GetVacanciesCandidate($filterVacancy: FilterVacancy) {
    Vacancies: getVacanciesCandidate(filterVacancy: $filterVacancy) {
      ...${vacancyFragment}
    }
  }
`

export const GET_VACANCY_BY_ID_QUERY = gql `
  query GetVacancyById($id: ID!, $fromCandidate: Boolean) {
    vacancy: getVacancyById(id: $id, fromCandidate: $fromCandidate) {
      ...${VacancyFragmentCandiadate}
    }
  }
`

export const GET_VACANCY_ENTITY_QUERY = gql`
  query getVacacnyEntity($filterVacancy: FilterVacancy) {
    Vacancies: getVacacnyEntity(filterVacancy: $filterVacancy) {
      ...${vacancyFragment}
    }
  }
`
export const GET_UNSOLICITED_ENTITY = gql `
  query getUnsolicitedEntities {
    getUnsolicitedEntities{
      entityId
    }
  }
`