<template>
  <v-container class="fill-heigt pt-8" fluid>
    <v-row class="d-flex flex-row justify-space-between align-center px-3">
      <div>
        <v-btn icon color="primary" @click="routerBack()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </div>
      <div
        v-if="$route.query.from === 'ranking'"
        class="d-flex flex-row align-center"
      >
        <div class="text--disabled">{{ $t("assign_rating") }}:</div>
        <div class="text-center mr-2">
          <v-rating
            v-model="rating"
            color="yellow darken-3"
            background-color="grey darken-1"
            empty-icon="$ratingFull"
            half-increments
            hover
            small
            @input="setRating()"
          ></v-rating>
        </div>
      </div>
      <div v-else>
        <template>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-chip
                v-on="on"
                link
                label
                outlined
                color="primary"
                class="mx-2"
                @click="dialogNote = true"
                >{{ $t("Notepad") }}</v-chip
              >
            </template>
            <div v-html="$t('tooltip_notpad')"></div>
          </v-tooltip>
        </template>

        <template>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="primary"
                dark
                @click="dialogList = true"
                class="px-6"
              >
                <v-icon small>mdi-plus</v-icon>{{ $t("AddtoFavorites") }}
              </v-btn>
            </template>
            <div v-html="$t('tooltip_favlist_candidate')"></div>
          </v-tooltip>
        </template>
      </div>
    </v-row>
    <v-row class="mt-7">
      <v-col cols="12" md="9" v-if="getCandidate">
        <profile-details
          :candidate="getCandidate"
          :filter="filter"
          :vacancyId="$route.query.vacancyId"
        />
      </v-col>
      <v-col cols="12" md="3" class="pt-0">
        <!-- <pre>
          {{ valueTLanguage()/3 }}
          {{ valueTLanguage()/2 }}
          {{ valueTLanguage()/1 }}
        </pre> -->
        <div
          v-if="
            $route.query.from != 'ranking' &&
            valueTLanguage() &&
            vacancy.weight &&
            valueTFormation() &&
            valueTProfessionalExperience()
          "
        >
          <div class="mb-2 text-uppercase">
            {{ $t("Matching_with_the_vacancy") }}
          </div>
          <v-divider class="py-3"></v-divider>
          <v-sheet color="white" elevation="2" width="100%">
            <!-- <v-row class="d-flex flex-row justify-end align-center ml-3 mb-3"> -->
            <div class="justify-center align-center ml-3 pb-1">
              <div class="mb-3 pt-3" v-if="valueTLanguage()">
                {{ $t("languages") }}
                <v-progress-linear
                  :color="
                    getLanguageVacancyPerc(candidate, vacancy) <=
                    valueTLanguage() / 3
                      ? 'red'
                      : getLanguageVacancyPerc(candidate, vacancy) <=
                        valueTLanguage() / 2
                      ? 'orange'
                      : 'success'
                  "
                  height="10"
                  :value="getLanguageVacancyPerc(candidate, vacancy)"
                  style="max-width: 300px; min-width: 225px"
                  :buffer-value="valueTLanguage()"
                  ><span class="caption"
                    >{{
                      DFormatter(getLanguageVacancyPerc(candidate, vacancy))
                    }}%</span
                  ></v-progress-linear
                >
              </div>
              <div class="mb-3" v-if="vacancy.weight">
                {{ $t("Related_areas") }}
                <v-progress-linear
                  :color="
                    getAreaCandVacancyPer(candidate, vacancy) <=
                    vacancy.weight / 3
                      ? 'red'
                      : getAreaCandVacancyPer(candidate, vacancy) <=
                        vacancy.weight / 2
                      ? 'orange'
                      : 'success'
                  "
                  height="10"
                  style="max-width: 300px; min-width: 225px"
                  :value="getAreaCandVacancyPer(candidate, vacancy)"
                  :buffer-value="vacancy.weight"
                  ><span class="caption"
                    >{{
                      DFormatter(getAreaCandVacancyPer(candidate, vacancy))
                    }}%</span
                  ></v-progress-linear
                >
              </div>
              <div class="mb-3" v-if="valueTFormation()">
                {{ $t("form_candidate_academic_level") }}
                <v-progress-linear
                  :color="
                    getFormationVacancyPerc(candidate, vacancy) <=
                    valueTFormation() / 3
                      ? 'red'
                      : getFormationVacancyPerc(candidate, vacancy) <=
                        valueTFormation() / 2
                      ? 'orange'
                      : 'success'
                  "
                  height="10"
                  style="max-width: 300px; min-width: 225px"
                  :value="getFormationVacancyPerc(candidate, vacancy)"
                  :buffer-value="valueTFormation()"
                  ><span class="caption"
                    >{{
                      DFormatter(getFormationVacancyPerc(candidate, vacancy))
                    }}%</span
                  ></v-progress-linear
                >
              </div>
              <div class="mb-6" v-if="valueTProfessionalExperience()">
                {{ $t("form_candidate_experience_label") }}
                <v-progress-linear
                  :color="
                    getExpCandVacancyPer(candidate, vacancy) <=
                    valueTProfessionalExperience() / 3
                      ? 'red'
                      : getExpCandVacancyPer(candidate, vacancy) <=
                        valueTProfessionalExperience() / 2
                      ? 'orange'
                      : 'success'
                  "
                  height="10"
                  style="max-width: 300px; min-width: 225px"
                  :value="getExpCandVacancyPer(candidate, vacancy)"
                  :buffer-value="valueTProfessionalExperience()"
                  ><span class="caption"
                    >{{
                      DFormatter(getExpCandVacancyPer(candidate, vacancy))
                    }}%</span
                  ></v-progress-linear
                >
              </div>
            </div>
            <!-- </v-row> -->
          </v-sheet>
        </div>
        <div class="mb-2 text-uppercase mt-3">{{ $t("Profilesnotseen") }}</div>
        <v-divider class="py-3"></v-divider>
        <!-- <template > -->
          <v-row>

         
        <v-col class="list">
          <div v-for="can in candidates" :key="can.id">
            <v-card class="mb-2 pa-6 py-5" @click="go(can.id)">
              <v-list-item>
                <v-list-item-avatar class="mx-auto" color="grey" size="38">
                  <img
                    :src="
                      can.photo
                        ? `${apiUrl}/images/candidate/${can.photo}`
                        : `/avatar.png`
                    "
                    alt="John"
                  />
                </v-list-item-avatar>

                <v-list-item two-line>
                  <v-list-item-content>
                    <!-- <pre>
                    {{can.name}}
                  </pre> -->
                    <v-list-item-title class="subtitle-2 font-weight-medium"
                      ><strong>{{ can.name.split(" ")[0] }}</strong>
                      {{ can.lastName.split(" ")[0] }}</v-list-item-title
                    >
                    <div class="caption text--disabled subtitle">
                      <span style="color: #0098CE">{{ can.profession }}</span>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item>
            </v-card>
          </div>
       </v-col>
        </v-row>
        <!-- </template> -->
      </v-col>
    </v-row>

    <note-candidate-dialog
      :candidateId="getCandidate"
      :dialog="dialogNote"
      :ranking="false"
      :vacancyId="vacancy"
      @close="dialogNote = false"
    />
    <add-candidate-list-dialog
      :candidateId="candidateId"
      :dialog1="dialogList"
      :vacancyId="vacancyId"
      @close="dialogList = false"
    />
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />
    <progress-dialog :processing="isLoading" />
  </v-container>
</template>

<script>
import { API_URL } from "@/api";
import { UPDATE_CANDIDATE_RATING_MUTATION } from "./../graphql/Mutation.resolver";
import {
  GET_CANDIDATE_BY_ID_QUERY,
  GET_CANDIDATES_ENTITY_QUERY,
} from "./../../candidate/graphql/Query";
import { ADD_DIALOG } from "@/mixins/dialog";
import matchCandidate from "@/mixins/matchCandidate";
import { mapActions, mapGetters } from "vuex";
import { formatError } from "@/utils";
import NoteCandidateDialog from "./../components/NoteCandidateDialog";
import ProfileDetails from "../components/ProfileDetails.vue";
import AddCandidateListDialog from "../components/AddCandidateListDialog.vue";
import ErrorDialog from "../../../components/ErrorDialog.vue";
import ProgressDialog from "../../../components/ProgressDialog.vue";
import { GET_VACANCY_BY_ID_QUERY } from "../../../../candidate/modules/dashboard/graphql/Query";
export default {
  name: "Profile",
  components: {
    NoteCandidateDialog,
    ProfileDetails,
    AddCandidateListDialog,
    ErrorDialog,
    ProgressDialog,
  },
  mixins: [ADD_DIALOG, matchCandidate],
  apollo: {
    candidate: {
      query: GET_CANDIDATE_BY_ID_QUERY,
      variables() {
        return {
          id: this.candidateId,
        };
      },
      skip() {
        return this.skipQueryC;
      },
      result({ data, loading }) {
        if (!loading) {
          this.candidate = data.candidate;
          const index = this.candidate.ratings.findIndex(
            (r) => r.entity == this.currentUser.entity.id
          );
          this.rating = index > -1 ? this.candidate.ratings[index].value : 0;
        }
      },
    },
    vacancy: {
      query: GET_VACANCY_BY_ID_QUERY,
      variables() {
        return {
          id: this.vacancyId,
        };
      },
      fetchPolicy: "no-cache",
      result({ data, loading }) {
        console.log("data.vacancy", data.vacancy);
        if (!loading) {
          this.vacancy = data.vacancy;
        }
      },
    },
    candidates: {
      query: GET_CANDIDATES_ENTITY_QUERY,
      variables() {
        return { filterCandidate: this.filter };
      },
      skip() {
        return this.skipQueryCacndidates;
      },
      result({ data, loading }) {
        console.log("data.candidates.candidatess", data.candidates.candidates);
        if (!loading) {
          this.candidates = data.candidates.candidates.filter(
            (c) => c.id != this.candidateId
          );
        }
      },
    },
  },
  data: () => ({
    apiUrl: API_URL,
    candidateId: undefined,
    candidate: {},
    candidates: [],
    dialogList: false,
    dialogNote: false,
    filter: {
      areasInterest: undefined,
      belongsToMany: undefined,
      favorites: undefined,
      formation: [
        {
          course: undefined,
          level: undefined,
        },
      ],
      languages: [
        {
          name: undefined,
          level: undefined,
        },
      ],
      ids: undefined,
      limit: 100,
      maxYear: undefined,
      minYear: undefined,
      maxSalary: undefined,
      minSalary: undefined,
      page: 1,
      professionalExperience: undefined,
    },
    rating: 0,
    skipQueryC: true,
    skipQueryCacndidates: true,
    skipQuery: true,
    vacancy: {},
    vacancyId: undefined,
  }),
  created() {
    this.start();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      // getCandidate: 'candidate/getCandidate'
    }),
    getCandidate() {
      return this.candidate;
    },
  },
  methods: {
    ...mapActions({}),
    start() {
      this.candidateId = this.$route.params.id;
      this.vacancyId = this.$route.query.vacancyId;
      this.filter = this.$route.query.filter
        ? { ...this.$route.query.filter }
        : this.filter;
      this.filter.limit = 100;
      this.fetchCandidate();
      this.fetchCandidates();
    },
    async fetchCandidate() {
      this.$apollo.queries.candidate.skip = false;
      await this.$apollo.queries.candidate.refetch();
    },
    async fetchCandidates() {
      this.$apollo.queries.candidates.skip = false;
      await this.$apollo.queries.candidates.refetch();
    },
    go(candidateId) {
      this.candidateId = candidateId;
      var index = this.candidates.findIndex((i) => i.id === this.candidateId);
      console.log(index);
      if (index > -1) {
        this.candidates.splice(index, 1);
      }
      this.fetchCandidate();
      //this.fetchCandidates();
    },
    valueTLanguage() {
      let total = 0;
      this.vacancy.languages.forEach((element) => {
        total += element.weight;
      });
      return total;
    },
    valueTProfessionalExperience() {
      let total = 0;
      this.vacancy.professionalExperience.forEach((element) => {
        total += element.weight;
      });
      return total;
    },
    valueTFormation() {
      let total = 0;
      this.vacancy.formation.forEach((element) => {
        total += element.weight;
      });
      return total;
    },
    DFormatter(num) {
      let mtnLocale = Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }).format(num);
      return mtnLocale;
    },
    routerBack() {
      switch (this.$route.query.from) {
        case "candidacies":
          this.$router.push({
            path: "/dashboard/entity/candidacies",
            query: { filter: this.filter, vacancyId: this.vacancyId },
          });
          break;

        case "ranking":
          this.$router.push({
            path: "/dashboard/entity/ranking",
            query: { filter: this.filter, vacancyId: this.vacancyId },
          });
          break;

        default:
          this.$router.push({
            path: "/dashboard/entity/candidates",
            query: { filter: this.filter },
          });
          break;
      }
    },
    async setRating() {
      try {
        this.isLoading = true;
        await this.$apollo.mutate({
          mutation: UPDATE_CANDIDATE_RATING_MUTATION,
          variables: { id: this.candidate.id, rating: this.rating },
        });
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped>
.list {
  height: 500px;
  overflow-y: auto;
}

::-webkit-scrollbar-thumb:vertical {
  height: 1px;
  background-color: #fff;
}
::-webkit-scrollbar-thumb:horizontal {
  height: 1px;
  background-color: #fff;
}
</style>
