import yearsMixins from "@/mixins/years";

export default {
  mixins: [
    yearsMixins,
  ],
  data: () => ({
    levelLanguage: [{
        text: "Básico",
        key: "basic"
      },
      {
        text: "Intermédio",
        key: "intermediate"
      },
      {
        text: "Avançado",
        key: "advanced"
      },
    ],
    levelAcademic: [{
        key: "basic",
        text: "Básico"
      },
      {
        key: "elementary",
        text: "Elementar"
      },
      {
        key: "dedium",
        text: "Médio"
      },
      {
        key: "bachelor",
        text: "Bacharelato"
      },
      {
        key: "graduation",
        text: "Licenciatura"
      },
      {
        key: "master_degree",
        text: "Mestrado"
      },
      {
        key: "doctorate",
        text: "Doutoramento"
      },
      {
        key: "Phd",
        text: "Phd"
      },
    ],
  }),
  methods: {
    getLanguageVacancyPerc(candidate, vacancy) {
      let total = 0;
      let weight = 0;
      if (vacancy.languages) {
        let languageVacancy = vacancy.languages.filter((l) => {
          return l;
        });
        for (let index = 0; index < languageVacancy.length; index++) {
          weight = 0
          if (languageVacancy[index].name === 'Português' || languageVacancy[index].name === 'Portuguese') {
            candidate.languages.forEach(l => {
              if (l.name === 'portuguese') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }

              }
            });
          }
          if (languageVacancy[index].name === 'Inglês' || languageVacancy[index].name === 'English') {

            candidate.languages.forEach(l => {
              if (l.name === 'english') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }

              }
            });
          }
          if (languageVacancy[index].name === 'Françês' || languageVacancy[index].name === 'French') {

            candidate.languages.forEach(l => {
              if (l.name === 'french') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }
          if (languageVacancy[index].name === 'Alemão' || languageVacancy[index].name === 'German') {
            candidate.languages.forEach(l => {
              if (l.name === 'german') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }
          if (languageVacancy[index].name === 'Espanhol' || languageVacancy[index].name === 'Spanish') {

            candidate.languages.forEach(l => {
              if (l.name === 'spanish') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }
        }

      }
      // console.log(weight);
      return total
    },
    getFormationVacancyPerc(candidate, vacancy) {

      let total = 0;
      let weight = 0;
      if (vacancy.formation) {
        let formationVacancy = vacancy.formation.filter((l) => {
          return l;
        });

        for (let index = 0; index < formationVacancy.length; index++) {
          weight = 0;
          let formation = candidate.formation.filter(
            (form) => form.course === formationVacancy[index].course
          );
          if (formation.length > 0) {
            weight = formationVacancy[index].weight / 2;
            const candFormLevel = this.levelAcademic.findIndex(i => i.key === formation[0].level) + 1
            const vacFormLevel = this.levelAcademic.findIndex(i => i.key === formationVacancy[index].level) + 1
            if (((candFormLevel * weight) / vacFormLevel) >= weight) {
              total += formationVacancy[index].weight
            } else {
              total += weight + (candFormLevel * weight) / vacFormLevel
            }
          }
        }
      }
      // console.log(weight);
      return total;
    },
    getAreaCandVacancyPer(candidate, vacancy) {
      let total = 0;
      let result;
      if (vacancy.areaInterest) {
        for (let index = 0; index < vacancy.areaInterest.length; index++) {
          result = candidate.areasInterest.filter(
            (exp) => exp === vacancy.areaInterest[index]
          );
          if (result) {
            total = vacancy.weight;
          }
        }
      }
      // console.log(result);
      return total;
    },
    getExpCandVacancyPer(candidate, vacancy) {
      let experience = 0;
      let yearsExperience = 0;
      let candidateExpYears = this.days(candidate.professionalExperience);

      if (vacancy.professionalExperience) {
        if (vacancy.professionalExperience[0]) {
          if (vacancy.professionalExperience[0].yearsExperience != 0) {
            yearsExperience =
              (candidateExpYears * vacancy.professionalExperience[0].weight) /
              vacancy.professionalExperience[0].yearsExperience;
          
          } else {
            if (candidateExpYears === vacancy.professionalExperience[0].yearsExperience) {
              yearsExperience = vacancy.professionalExperience[0].weight;
            } else {
              yearsExperience = candidateExpYears;
            }
            if (vacancy.professionalExperience[0].yearsExperience===0) {
              // experience = 0
              yearsExperience = vacancy.professionalExperience[0].weight
            }
          }
          if (yearsExperience >= vacancy.professionalExperience[0].weight) {
            experience = vacancy.professionalExperience[0].weight;
          } else {
            experience = yearsExperience;
          }
        }
      }else{
        experience = 0
      }
      // console.log(yearsExperience, candidateExpYears,experience);
      return experience;
    },
  }
}